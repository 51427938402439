import React,{useEffect} from 'react'
import Navebar from '../components/navebar/Navebar'
import Footer from '../components/Footer/Footer'
import { Outlet } from 'react-router-dom'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import { useLocation } from 'react-router-dom'
import UsePageViews from '../components/UsePageViews'




export default function MainLayout() {
  const { pathname } = useLocation();
  UsePageViews()

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
        <Navebar />
          <Outlet />
          <FloatingWhatsApp phoneNumber={'+212760804211'} accountName={'Damay'} avatar={'/03-PNG/01-Logo DAMAY RSG VF.png'} statusMessage={'online'} chatMessage={'Bonjour مرحبا'}/>
        <Footer />
    </div>
  )
}
