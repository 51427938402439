/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from 'react';
import './index.css';
import 'leaflet/dist/leaflet.css';
import { IconButton } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ContactezNous } from '../../postApi/Axios';
import CircularIndeterminate from '../../components/loading/Loading';

export default function ContactUs() {
    const [Loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
        name: '',
        tel: '',
        subject: '',
        message: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const validateForm = () => {
        let isValid = true;

        if (!formData.name.trim()) {
            toast.error("Name is required.");
            isValid = false;
        }
        if (!formData.email.trim()) {
            toast.error("Email is required.");
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            toast.error("Invalid email format.");
            isValid = false;
        }
        if (!formData.tel.trim()) {
            toast.error("Telephone is required.");
            isValid = false;
        } else if (!/^\d{10}$/.test(formData.tel)) {
            toast.error("Telephone must be 10 digits.");
            isValid = false;
        }
        if (!formData.subject.trim()) {
            toast.error("Subject is required.");
            isValid = false;
        }
        if (!formData.message.trim()) {
            toast.error("Message is required.");
            isValid = false;
        }

        return isValid;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateForm()) {
            return;
        }

        setLoading(true);
        try {
            const response = await ContactezNous(formData);
            if (response.status === 201) {
                toast.success("Message sent successfully");
                setFormData({
                    email: '',
                    name: '',
                    tel: '',
                    subject: '',
                    message: ''
                });
            } else {
                toast.error(`Error sending message: ${response.statusText}`);
            }
        } catch (error) {
            console.error('Error during submission:', error);
            toast.error("An error occurred while sending the message");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='ContactUsPage'>
            {Loading && <CircularIndeterminate />}
            <div className='bannerContact'>
                <img src='/contactus.jpg' alt='banner' />
            </div>
            <div className='container-fluid container-xl'>
                <div className='row my-4 align-items-center p-3' style={{ backgroundColor: '#fbfbfb', boxShadow: '0px 0px 10px 2px #f5f5f5' }}>

                    <div className='col-12 col-md-7 m-0 ps-lx-5'>
                        <div className='adress'>
                            <h1 style={{ fontSize: '33px' }} className='section_title'>Bienvenue dans votre espace assistance DAMAY</h1>
                            <div className='adressHeader my-3'>
                                <p className='m-0'>Nous sommmes là pour vous aider 6 jours / 7 de 8:30h à 17h </p>
                            </div>
                            <div className='line mb-3'></div>
                            <h2 className='adressContent'> 21 Boulevard Lahcen OuIder casablanca</h2>
                            <div className='d-flex justify-content-start align-items-center'>
                                <img style={{ width: '90px', aspectRatio: 1 }} src={'/Call.gif'} alt='call' />
                                <div className='phoneEmail ms-4'>
                                    <p className='mb-0 text-start' style={{ fontSize: '21px', fontWeight: '600', color: 'black', lineHeight: '20px' }}>+212 760-804211</p>
                                    <p className='text-center mb-0' style={{ fontSize: '18px', fontWeight: '600', color: '#000000' }}>contact@damay-co.com</p>
                                </div>
                            </div>
                            <div style={{ width: "100%" }} className='socialMedia d-flex justify-content-evenly my-5'>
                                <a
                                    style={{ width: '18%' }}
                                    className='whatsapp'
                                    href='https://wa.me/212760804211'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    <img
                                        style={{ width: '100%', aspectRatio: 1 }}
                                        src={'/03-PNG/whatsapp_733585.png'}
                                        alt='icons social media'
                                    />
                                </a>

                                <a target="_blank" href='https://facebook.com/DAMAYMAROC' style={{ width: '18%' }} className='facebook'>
                                    <img style={{ width: '100%', aspectRatio: 1 }} src={'/03-PNG/facebook_5968764.png'} alt='icons social media' />
                                </a>

                                <a target="_blank" href='https://www.instagram.com/damaymaroc/' style={{ width: '18%' }} className='instagram'>
                                    <img style={{ width: '100%', aspectRatio: 1 }} src={'/03-PNG/instagram_2111463.png'} alt='icons social media' />
                                </a>

                                <a target="_blank" href='https://www.tiktok.com/@damayofficiel' style={{ width: '18%' }} className='tiktok'>
                                    <img style={{ width: '100%', aspectRatio: 1 }} src={'/03-PNG/tiktok_3116491.png'} alt='icons social media' />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-5 p-0'>
                        <div className='map'>
                            <div style={{ width: '100%' }}>
                                <iframe
                                    width="100%"
                                    height="600"
                                    frameborder="0"
                                    scrolling="no"
                                    marginheight="0"
                                    marginwidth="0"
                                    src="https://maps.google.com/maps?width=100%&height=600&hl=en&q=33.587528,-7.6117231&ie=UTF8&t=&z=14&iwloc=B&output=embed"
                                >
                                    <a href="https://www.gps.ie/">gps vehicle tracker</a>
                                </iframe>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='formContactUs text-center p-2'>
                    <div className='my-3'>
                        <h4 className='text-center mb-2'>Contactez-Nous</h4>
                        <h2 className='text-center mb-2'>Rédiger un Message</h2>
                    </div>
                    <div className='row justify-content-center'>
                        <form className='col-12 col-md-8 my-3' onSubmit={handleSubmit} >
                            <div className='inputsContactUs'>
                                <div className='row '>
                                    <div className='col-12 col-md-6 p-0 my-1 '><input type='email' name='email' value={formData.email} onChange={handleChange} placeholder='Email' /></div>
                                    <div className='col-12 col-md-6 p-0 my-1 '><input type='text' name='name' value={formData.name} onChange={handleChange} placeholder='Nom' /></div>                                </div>

                                <div className='row'>
                                    <div className='col-12 col-md-6 p-0 my-1 '><input type='text' name='tel' value={formData.tel} onChange={handleChange} placeholder='Tel' /></div>
                                    <div className='col-12 col-md-6 p-0 my-1 '><input type='text' name='subject' value={formData.subject} onChange={handleChange} placeholder='Sujet' /></div>                                </div>
                            </div>

                            <div className='row justify-content-center my-2'>
                                <textarea rows="7" className='col-12' name="message" value={formData.message} onChange={handleChange} placeholder='Message'></textarea>                            </div>
                            <IconButton type='submit' className='my-3'>soumettre</IconButton>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
