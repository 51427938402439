import React, { useEffect, useState } from 'react'
import './index.css'
import IconButton from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';


export default function Robots() {
  const robots = useSelector(state => state.Products.robots)
  const [selectedCapacities, setSelectedCapacities] = useState({});
  const [securityFilter, setSecurityFilter] = useState(null); 

  const remiseProducts = {
    // "000.000.022": { oldPrice: 650 },
    // "000.000.771": { oldPrice: 600 }, 
    // "000.000.016": { oldPrice: 750},
    // "000.000.024": { oldPrice: 900},
    // "000.000.769": { oldPrice: 1000 },
    // "000.000.025": { oldPrice: 1100 },
    // "000.000.017": { oldPrice: 1100 },
  };

  const flattenedProducts = robots.flatMap(product =>
    product.variants.map(variant => ({
      ...product,
      variant
    }))
  );

  const allPrices = flattenedProducts.map(item => item.variant.price);
  const MIN = Math.min(...allPrices);
  const MAX = Math.max(...allPrices);
  const marks = [
    {
      value: MIN,
      label: '',
    },
    {
      value: MAX,
      label: '',
    },
  ];

  // Handler for resetting all filters
  const handleResetAll = () => {
    setVal(MAX);
    setSelectedCapacities({});
    setSecurityFilter(null);
  };


  // State for price filter
  const [val, setVal] = useState(MAX);
  useEffect(() => {
    setVal(MAX);
  }, [MAX]);

  const handleChange = (_, newValue) => {
    setVal(newValue);
  };


  // Handler for checkbox change capacity filter
  const handleCheckboxChange = (capacity) => {
    setSelectedCapacities(prevState => {
      const newState = {
        ...prevState,
        [capacity]: !prevState[capacity] // Toggle the selected state
      };
      // Check if all values are false
      const allDeselected = Object.values(newState).every(value => !value);
      // If all are deselected, return an empty object
      if (allDeselected) {
        return {};
      }
      return newState;
    });
  };

  // Handler for security filter change
  const handleSecurityFilterChange = (filter) => {
    setSecurityFilter(filter);
  };

  // Filter products by selected price, capacities, and security
  const filteredProducts = flattenedProducts.filter(item => {
    const priceMatch = item.variant.price <= val;
    const capacityMatch = Object.keys(selectedCapacities).length === 0 || !!selectedCapacities[item.variant.capacity];
    const securityMatch = securityFilter === null || item.variant.security === securityFilter;
    return priceMatch && capacityMatch && securityMatch;
  });



  return (
    <div className='RobotsPage py-3'>
      <div className='container-fluid container-xxl'>
        <div className='row'>
          <div style={{ backgroundColor: '#e9e9e95b', borderRadius: "8px" }} className='col-12 col-md-4 col-lg-3'>
            <div className='FilterSection p-4 py-5'>
              <div className='FilterCapacite'>
                <h2 className='section_title'>FILTRÉ PAR :</h2>
                {/*Filter par capacity*/}
                <div className='d-flex  flex-column'>
                  {robots.map(item => (
                    <FormControlLabel
                      key={item.id}
                      control={
                        <Checkbox
                          checked={!!selectedCapacities[item.variants[0].capacity]}
                          onChange={() => handleCheckboxChange(item.variants[0].capacity)}
                        />
                      }
                      label={item.variants[0].capacity + ' g'}
                    />
                  ))}
                </div>
                {/*End Filter par security*/}

              </div>
              {/*Filter par security*/}
              <div className='FilterSecurite my-4 d-flex flex-lg-row justify-content-lg-between align-items-lg-center flex-column'>
                <IconButton
                  className={`btn_securite my-1 me-1 ${securityFilter === 1 ? 'chosen' : ''}`}
                  onClick={() => handleSecurityFilterChange(1)}
                >
                  Avec sécurité
                </IconButton>
                <IconButton
                  className={`btn_securite my-1 ${securityFilter === 0 ? 'chosen' : ''}`}
                  onClick={() => handleSecurityFilterChange(0)}
                >
                  Sans sécurité
                </IconButton>
              </div>
              {/*End Filter par security*/}

              {/*Filter par prix*/}
              <div className='FilterPrice my-4'>
                <Box sx={{ width: 250, maxWidth: '100%' }}>
                  <Slider
                    marks={marks}
                    step={10}
                    value={val}
                    valueLabelDisplay="auto"
                    min={MIN}
                    max={MAX}
                    onChange={handleChange}
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography
                      variant="body2"
                      onClick={() => setVal(MIN)}
                      sx={{ cursor: 'pointer' }}
                    >
                      {MIN} Dhs
                    </Typography>
                    <Typography
                      variant="body2"
                      onClick={() => setVal(MAX)}
                      sx={{ cursor: 'pointer' }}
                    >
                      {MAX} Dhs
                    </Typography>
                  </Box>
                </Box>
              </div>
              {/*End Filter par prix*/}
              <button className='btn btn-danger' onClick={handleResetAll}>
                tous les produits
              </button>
            </div>

          </div>
          <div className='col-12 col-md-8 col-lg-9'>
            <div className='ProductsSection'>
              <div className='row'>
                {filteredProducts.map(item => (
                  <div key={item.variant.id} className='col-12 col-sm-6 col-lg-3 my-3'>
                    <div className='card position-relative'>
                    {remiseProducts[item.variant.Ref] && (
                      <div className='remisecard' style={{ zIndex:999 }}>
                        <p className='mb-0'>-20%</p>
                      </div>
                     )}
                      <IconButton style={{ borderRadius: 0, padding: 0 }} className='card-image justify-content-center d-flex flex-column'>
                        <img
                          style={{ width: '85%', aspectRatio: 1 }}
                          src={item.variant.images.length > 0 ? item.variant.images[0].image_url : undefined}
                          alt='related product'
                        />
                        {item.variant.security === 1 ? (
                          <div className='secure-label'>
                            Avec sécurité
                          </div>
                        ) : (<div className='secure-label'>
                          Sans sécurité
                        </div>)}
                      </IconButton>
                      <div className='card-body'>
                        <div className='productName d-flex justify-content-center'>
                          <p className='m-0'>{item.name}</p>
                        </div>
                        <div className='addToCart_price d-flex justify-content-around align-items-center'>
                        {remiseProducts[item.variant.Ref] && (
                          <span className="old-price">{remiseProducts[item.variant.Ref].oldPrice.toFixed(2)} dh</span> 
                        )}
                          <h4 className='m-0'>{item.variant.price} Dhs</h4>
                        </div>
                      </div>
                      <Link className='linkToProductPage' to={`/Product/${item.slug}${item.variant.security === 1 ? '/security' : ''}`}></Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
